import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase-config';
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup
} from 'firebase/auth';
import { getDoc, setDoc, doc } from 'firebase/firestore';
import './auth.css';
import PlanSelection from './plan_selection';

const googleProvider = new GoogleAuthProvider();

const Auth = () => {
    const [isLoginMode, setIsLoginMode] = useState(true);
    const [error, setError] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('Basic');
    const [showPlanSelection, setShowPlanSelection] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        username: ''
    });
    const navigate = useNavigate();
    const location = useLocation();

    // Check if a plan was selected from the pricing page
    useEffect(() => {
        if (location.state?.selectedPlan) {
            setSelectedPlan(location.state.selectedPlan);
            setIsLoginMode(false); // Switch to signup mode if plan was selected
        }
    }, [location]);

    const handlePlanSelect = (planName) => {
        setSelectedPlan(planName);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);
        
        try {
            if (isLoginMode) {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    formData.email,
                    formData.password
                );
                const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
                const userData = userDoc.exists() ? userDoc.data() : null;
                
                if (!userData || !userData.subscriptionPlan) {
                    navigate('/select-plan', { replace: true });
                } else {
                    navigate('/');
                }
            } else {
                if (!showPlanSelection) {
                    if (formData.password !== formData.confirmPassword) {
                        setError('Passwords do not match');
                        setIsLoading(false);
                        return;
                    }
                    setShowPlanSelection(true);
                    setIsLoading(false);
                    return;
                }
    
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    formData.email,
                    formData.password
                );
    
                const subscriptionPlan = selectedPlan.toLowerCase();
                await setDoc(doc(db, 'users', userCredential.user.uid), {
                    username: formData.username,
                    email: formData.email,
                    createdAt: new Date().toISOString(),
                    subscriptionPlan: subscriptionPlan
                });
    
                if (subscriptionPlan !== 'basic') {
                    navigate('/payment', { state: { plan: subscriptionPlan, isNewUser: true } });
                } else {
                    navigate('/');
                }
            }
        } catch (error) {
            console.error('Auth error:', error);
            setError(getAuthErrorMessage(error.code) || error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to get user-friendly error messages
    const getAuthErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/invalid-email':
                return 'Invalid email address format';
            case 'auth/user-disabled':
                return 'This account has been disabled';
            case 'auth/user-not-found':
                return 'No account found with this email';
            case 'auth/wrong-password':
                return 'Incorrect password';
            case 'auth/email-already-in-use':
                return 'An account with this email already exists';
            case 'auth/weak-password':
                return 'Password is too weak. Use at least 6 characters';
            case 'auth/network-request-failed':
                return 'Network error. Check your connection';
            case 'auth/too-many-requests':
                return 'Too many unsuccessful attempts. Try again later';
            default:
                return null;
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            setIsLoading(true);
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            
            // Create/update user document in Firestore
            const userRef = doc(db, 'users', user.uid);
            
            // Check if user doc already exists
            const userDoc = await getDoc(userRef);
            
            if (!userDoc.exists()) {
                // Create new user document
                await setDoc(userRef, {
                    email: user.email,
                    displayName: user.displayName,
                    isNewUser: true,
                    createdAt: new Date(),
                    subscriptionPlan: null,
                    setupComplete: false
                });
                
                // Redirect to select-plan with state
                navigate('/select-plan', { 
                    state: { fromGoogle: true },
                    replace: true
                });
            } else {
                // User exists - navigate based on their subscription status
                const userData = userDoc.data();
                
                if (!userData.subscriptionPlan) {
                    navigate('/select-plan', { replace: true });
                } else if (userData.subscriptionPlan !== 'basic' && !userData.setupComplete) {
                    navigate('/payment', { 
                        state: { plan: userData.subscriptionPlan, isNewUser: false },
                        replace: true
                    });
                } else {
                    navigate('/', { replace: true });
                }
            }
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            setError("Failed to sign in with Google. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const toggleMode = () => {
        setIsLoginMode(!isLoginMode);
        setShowPlanSelection(false);
        setFormData({
            email: '',
            password: '',
            confirmPassword: '',
            username: ''
        });
        setError('');
    };

    const handleBack = () => {
        navigate('/'); // Go to the homepage when clicking back
    };

    return (
        <div className="auth-container">
            <div className="auth-box-wrapper">
                <button onClick={handleBack} className="back-button">
                    ← Back
                </button>
                <div className="auth-box">
                    <div className="auth-left">
                        <div className="brand-logo">YourApp</div>
                        <div className="welcome-text">
                            <h1>{isLoginMode ? 'Welcome Back!' : 'Join Our Community'}</h1>
                            <p>
                                {isLoginMode 
                                    ? 'Securely sign in to your account to access all your personalized features and content.' 
                                    : 'Create an account to get started with our premium service and enjoy all the benefits.'}
                            </p>
                        </div>
                        <div className="features-list">
                            <ul>
                                <li>Access premium content</li>
                                <li>Personalized recommendations</li>
                                <li>Save your favorites</li>
                                <li>Sync across devices</li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="auth-right">
                        {!isLoginMode && showPlanSelection ? (
                            <>
                                <div className="auth-header">
                                    <h2>Choose Your Plan</h2>
                                    <p>Select a plan that fits your needs</p>
                                </div>
                                <PlanSelection 
                                    onPlanSelect={handlePlanSelect} 
                                    selectedPlan={selectedPlan} 
                                />
                                <button 
                                    onClick={handleSubmit} 
                                    className="auth-submit mt-4"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Processing...' : `Continue to ${selectedPlan !== 'Basic' ? 'Payment' : 'Dashboard'}`}
                                </button>
                            </>
                        ) : (
                            <>
                                <div className="auth-header">
                                    <h2>{isLoginMode ? 'Sign In' : 'Create Account'}</h2>
                                    <p>{isLoginMode ? 'Please log in to continue' : 'Sign up to get started'}</p>
                                </div>
                                
                                {/* Email/Password Form */}
                                <form onSubmit={handleSubmit} className="auth-form">
                                    {!isLoginMode && (
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="username"
                                                value={formData.username}
                                                onChange={handleInputChange}
                                                placeholder="Username"
                                                required
                                            />
                                        </div>
                                    )}

                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            placeholder="Email"
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            placeholder="Password"
                                            required
                                        />
                                    </div>

                                    {!isLoginMode && (
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                value={formData.confirmPassword}
                                                onChange={handleInputChange}
                                                placeholder="Confirm Password"
                                                required
                                            />
                                        </div>
                                    )}

                                    <button 
                                        type="submit" 
                                        className="auth-submit"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Please wait...' : (isLoginMode ? 'Sign In' : 'Continue')}
                                    </button>
                                </form>

                                {(
                                    <div className="social-auth">
                                        <button 
                                            onClick={handleGoogleSignIn} 
                                            className="google-auth-button"
                                            disabled={isLoading}
                                        >
                                            <svg className="google-icon" viewBox="0 0 24 24">
                                                <path fill="#EA4335" d="M5.26620003,9.76452941 C6.19878754,6.93863203 8.85444915,4.90909091 12,4.90909091 C13.6909091,4.90909091 15.2181818,5.50909091 16.4181818,6.49090909 L19.9090909,3 C17.7818182,1.14545455 15.0545455,0 12,0 C7.27006974,0 3.1977497,2.69829785 1.23999023,6.65002441 L5.26620003,9.76452941 Z"/>
                                                <path fill="#34A853" d="M16.0407269,18.0125889 C14.9509167,18.7163016 13.5660892,19.0909091 12,19.0909091 C8.86648613,19.0909091 6.21911939,17.076871 5.27698177,14.2678769 L1.23746264,17.3349879 C3.19279051,21.2936293 7.26500293,24 12,24 C14.9328362,24 17.7353462,22.9573905 19.834192,20.9995801 L16.0407269,18.0125889 Z"/>
                                                <path fill="#4A90E2" d="M19.834192,20.9995801 C22.0291676,18.9520994 23.4545455,15.903663 23.4545455,12 C23.4545455,11.2909091 23.3454545,10.5272727 23.1818182,9.81818182 L12,9.81818182 L12,14.4545455 L18.4363636,14.4545455 C18.1187732,16.013626 17.2662994,17.2212117 16.0407269,18.0125889 L19.834192,20.9995801 Z"/>
                                                <path fill="#FBBC05" d="M5.27698177,14.2678769 C5.03832634,13.556323 4.90909091,12.7937589 4.90909091,12 C4.90909091,11.2182781 5.03443647,10.4668121 5.26620003,9.76452941 L1.23999023,6.65002441 C0.43658717,8.26043162 0,10.0753848 0,12 C0,13.9195484 0.444780743,15.7301709 1.23746264,17.3349879 L5.27698177,14.2678769 Z"/>
                                            </svg>
                                            Continue with Google
                                        </button>
                                    </div>
                                )}

                                {error && <div className="error-message">{error}</div>}

                                <div className="auth-footer">
                                    <p>
                                        {isLoginMode ? "Don't have an account?" : "Already have an account?"}
                                        <button onClick={toggleMode} className="toggle-mode">
                                            {isLoginMode ? 'Sign Up' : 'Log In'}
                                        </button>
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;