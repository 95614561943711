import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './user_context';
import './pricing.css';

// Export plans array to be used in other components
export const plans = [
    {
        name: "Basic",
        price: "$0",
        duration: "/month",
        features: [
            "3 Optimization Requests",
            "Best for infrequent posts or testing the product"
        ],
        buttonText: "Get Started",
    },
    {
        name: "Creator",
        price: "$15",
        duration: "/month",
        tag: "Most Popular",
        features: [
            "10 Optimization Requests per day",
            "Best for regular content creation"
        ],
        buttonText: "Get Started",
    },
    {
        name: "Professional",
        price: "$30",
        duration: "/month",
        features: [
            "Unlimited optimization requests daily",
            "For daily content creation"
        ],
        buttonText: "Get Started",
    },
];

const Pricing = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const handlePlanSelect = (planName) => {
        // If user is not logged in, redirect to auth page
        if (!user) {
            navigate('/auth', { state: { selectedPlan: planName } });
            return;
        }

        // If selecting Basic plan, no payment needed
        if (planName.toLowerCase() === 'basic') {
            navigate('/account-subscription');
            return;
        }

        // For paid plans, navigate to payment with plan info
        navigate('/payment', { 
            state: { 
                plan: planName.toLowerCase(),
                isNewUser: false
            } 
        });
    };

    return (
        <div className="pricing-container">
            <h1 className="pricing-title">Choose the right plan for you</h1>
            <div className="pricing-cards">
                {plans.map((plan, index) => (
                    <div key={index} className={`pricing-card ${plan.tag ? 'highlight' : ''}`}>
                        <div className="card-header">
                            <span className="plan-name">{plan.name}</span>
                            {plan.tag && <span className="plan-tag">{plan.tag}</span>}
                        </div>
                        <div className="price-section">
                            <span className="price">{plan.price}</span>
                            <span className="duration">{plan.duration}</span>
                        </div>
                        <ul className="features-list">
                            {plan.features.map((feature, i) => (
                                <li key={i}>{feature}</li>
                            ))}
                        </ul>
                        <button 
                            className="get-started-btn"
                            onClick={() => handlePlanSelect(plan.name)}
                        >
                            {plan.buttonText}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Pricing;