import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Search, LogOut, CreditCard } from 'lucide-react';
import { getAuth, signOut } from 'firebase/auth';
import { useUser } from './user_context'; // Import the user context
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { app } from '../firebase-config';
import axios from 'axios';
import './top_menu.css';

const TopMenu = () => {
    const { user: currentUser, profile, refreshProfile } = useUser();
    const [activeMenuItem, setActiveMenuItem] = useState("Home");
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [isLoadingPortal, setIsLoadingPortal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isSearchPage = location.pathname === '/search';
    const auth = getAuth();
    const db = getFirestore(app);
    
    // If we're on the search page, get the search query from the state
    useEffect(() => {
        if (isSearchPage && location.state?.searchQuery) {
            setSearchQuery(location.state.searchQuery);
        }
    }, [isSearchPage, location.state]);

    const handleUserMenuClick = async () => {
        // Toggle the menu state
        setShowUserMenu(!showUserMenu);
        
        // If opening the menu, refresh the profile first
        if (!showUserMenu && refreshProfile) {
            try {
                await refreshProfile();
            } catch (error) {
                console.error("Error refreshing profile:", error);
            }
        }
    };

    useEffect(() => {
        // This will run whenever currentUser changes
        if (currentUser) {
          const refreshProfile = async () => {
            try {
              const userDocRef = doc(db, 'users', currentUser.uid);
              const userSnapshot = await getDoc(userDocRef);
              if (userSnapshot.exists()) {
                console.log("Latest user data:", userSnapshot.data());
              }
            } catch (error) {
              console.error("Error refreshing profile:", error);
            }
          };
          
          refreshProfile();
        }
    }, [currentUser, db, location.pathname]); 

    const handleMenuClick = (text) => {
        setActiveMenuItem(text);
        // If "Home" is clicked, navigate to root path instead of /home
        if (text === "Home") {
            navigate('/');
        } else {
            navigate(`/${text.toLowerCase()}`);
        }
    };

    const handleAuthClick = () => {
        navigate('/auth');
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setShowUserMenu(false);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleManageSubscription = async () => {
        if (!currentUser) {
          alert('Please log in to manage your subscription.');
          return;
        }
        
        // Get a fresh copy of the profile data to ensure we have the latest
        try {
          if (refreshProfile) {
            await refreshProfile();
          }
        } catch (error) {
          console.error('Error refreshing profile:', error);
        }
      
        setIsLoadingPortal(true);
        try {
          // Get the API URL from environment variables or use a default
          const apiUrl = process.env.REACT_APP_API_URL || 'https://clickr8.onrender.com';
          
          // Log the current user profile information for debugging
          console.log('Current profile data:', profile);
          console.log('Current user:', currentUser.uid);
          
          // Get a fresh token for the request
          const token = await currentUser.getIdToken(true);
          
          const response = await axios({
            method: 'post',
            url: `${apiUrl}/api/create-customer-portal`,
            data: {
              userId: currentUser.uid
            },
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
      
          if (response.data && response.data.url) {
            window.location.href = response.data.url;
          } else {
            console.error('Invalid response:', response.data);
            throw new Error('Invalid portal URL response');
          }
        } catch (error) {
          console.error('Error opening subscription portal:', error);
          
          // Add more detailed error logging
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
            
            // Show more detailed error message to user
            alert(`Unable to access subscription management: ${error.response.data?.error || error.message}`);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received:', error.request);
            alert('Unable to connect to the subscription service. Please check your internet connection and try again.');
          } else {
            // Something happened in setting up the request that triggered an Error
            alert('Unable to access subscription management. Please try again later.');
          }
        } finally {
          setIsLoadingPortal(false);
          setShowUserMenu(false);
        }
      };

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!searchQuery.trim()) return;
    
        setIsSearching(true);
        try {
            // Get the API URL from environment variables or use a default
            const apiUrl = process.env.REACT_APP_API_URL || 'https://clickr8.onrender.com';
            const response = await fetch(`${apiUrl}/api/search?q=${encodeURIComponent(searchQuery)}`);
            const data = await response.json();
            navigate('/search', { 
                state: { 
                    searchResults: data,
                    searchQuery: searchQuery
                } 
            });
        } catch (error) {
            console.error('Error searching videos:', error);
        } finally {
            setIsSearching(false);
        }
    };

    const MenuItem = ({ text }) => (
        <button 
            className={`m-option ${activeMenuItem === text ? 'active' : ''}`}
            onClick={() => handleMenuClick(text)}
        >
            {text}
        </button>
    );

    // Determine subscription badge color
    const getSubscriptionBadgeColor = (plan) => {
        switch (plan) {
            case 'premium': return 'subscription-badge--premium';
            case 'pro': return 'subscription-badge--pro';
            case 'professional': return 'subscription-badge--pro';
            case 'creator': return 'subscription-badge--creator';
            default: return 'subscription-badge--basic';
        }
    };

    // Show the manage subscription option if user has a subscription ID and plan
    // Changed this condition to fix the issue with the missing button
    const showManageSubscription = profile && 
        (profile.stripeSubscriptionId &&
        profile.subscriptionPlan && 
        profile.subscriptionPlan !== 'basic');

        return (
            <div className={`top-menu-container ${isSearchPage ? 'search-page' : ''}`}>
                <div className="menu-and-logo">
                    {isSearchPage ? (
                        <div className="menu-section">
                            <div className="hamburger-menu-wrapper">
                                <button className="hamburger-menu">
                                    ☰
                                </button>
                                <div className="mobile-menu">
                                    <div className="mobile-menu-content">
                                        <MenuItem text="Home" />
                                        <MenuItem text="Pricing" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="menu-options">
                            <MenuItem text="Home" />
                            <MenuItem text="Pricing" />
                        </div>
                    )}
        
                    <div className="logo-section--compact">
                        <div className="logo">Click</div>
                        <div className="app-title">r8</div>
                        <img src="/images/logo.png" alt="logo" className="cursor-logo"/>
                    </div>
                </div>
                
                {/* Search form here for search pages, between menu/logo and sign-up section */}
                {isSearchPage && (
                    <form onSubmit={handleSearch} className="search-form search-form--menu">
                        <div className="search-bar-container">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search a Video Topic..."
                                className="search-input"
                                disabled={isSearching}
                            />
                        </div>
                        <button
                            type="submit"
                            className={`search-button ${isSearching ? 'searching' : ''}`}
                            disabled={isSearching}
                        >
                            {isSearching ? (
                                <span className="loading-container">
                                    <span className="spinner"></span>
                                </span>
                            ) : (
                                <Search className="search-icon" />
                            )}
                        </button>
                    </form>
                )}
                
                {/* Sign up section - now visible for both regular and search pages on desktop */}
                <div className="sign-up-section">
                    {currentUser ? (
                        <div className="user-profile">
                            <div 
                                className="user-avatar-container"
                                onClick={handleUserMenuClick}
                            >
                                {currentUser?.photoURL ? (
                                    <img 
                                        src={currentUser.photoURL} 
                                        alt="Profile" 
                                        className="user-avatar" 
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.style.display = 'none';
                                            e.target.nextSibling.style.display = 'flex';
                                        }}
                                    />
                                ) : (
                                    <div className="user-initial-avatar">
                                        {currentUser?.displayName ? currentUser.displayName[0].toUpperCase() : 
                                        currentUser?.email ? currentUser.email[0].toUpperCase() : 'U'}
                                    </div>
                                )}
                                <span className="user-name">{currentUser.displayName}</span>
                                {profile ? (
                                    <span className={`subscription-badge ${getSubscriptionBadgeColor(profile.subscriptionPlan)}`}>
                                        {profile.subscriptionPlan}
                                    </span>
                                ) : (
                                    <span className="subscription-badge"></span>
                                )}
                                
                                {showUserMenu && (
                                    <div className="user-dropdown-menu">
                                        {profile && profile.subscriptionPlan && profile.subscriptionPlan !== 'basic' && (
                                            <button 
                                                onClick={handleManageSubscription} 
                                                className="dropdown-item subscription-item"
                                                disabled={isLoadingPortal}
                                            >
                                                <CreditCard size={16} />
                                                <span>
                                                    {isLoadingPortal ? 'Loading...' : 'Manage Subscription'}
                                                </span>
                                            </button>
                                        )}
                                        <button onClick={handleLogout} className="dropdown-item logout-item">
                                            <LogOut size={16} />
                                            <span>Sign Out</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            <button className="login" onClick={handleAuthClick}>Log In</button>
                            <button className="signup" onClick={handleAuthClick}>Sign Up</button>
                        </>
                    )}
                </div>
            </div>
        );
};

export default TopMenu;