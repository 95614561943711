import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { useUser } from './user_context'; // Import useUser hook
import axios from 'axios';
import './payment.css'; // Reuse payment styles

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const PaymentSuccess = () => {
  // Use the useUser hook instead of useContext directly
  const { user, refreshProfile } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    // Extract the session_id from URL
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');

    if (!user) {
      // If user isn't logged in yet, wait briefly then redirect to login
      const timer = setTimeout(() => {
        navigate('/login', { state: { returnUrl: location.pathname + location.search } });
      }, 2000);
      return () => clearTimeout(timer);
    }

    if (!sessionId) {
      setError('Invalid session ID. Payment verification failed.');
      setLoading(false);
      return;
    }

    // Verify the payment with your backend
    // Update this in verifyPayment function
    const verifyPayment = async () => {
      try {
        // First, check if the user already has a subscription ID saved
        const userDocRef = doc(db, 'users', user.uid);
        
        // DON'T set to processing here, as it might override 'active' from the webhook
        // Instead, only update fields that don't affect the webhook's updates
        await updateDoc(userDocRef, {
          sessionId: sessionId,
          checkoutCompleted: true,
          checkoutCompletedTime: new Date().toISOString()
        });
        
        // The webhook should handle most updates, but we can verify the subscription status
        const response = await axios.get(`${apiUrl}/api/verify-checkout-session/${sessionId}`, {
          headers: {
            'Authorization': `Bearer ${await user.getIdToken()}`
          }
        });
        
        if (response.data && response.data.status === 'complete') {
          setSubscription(response.data);
          
          // Explicitly ensure status is active
          await updateDoc(userDocRef, {
            subscriptionStatus: 'active',
            setupComplete: true
          });
          
          // Refresh the user profile to get the latest data
          if (refreshProfile) {
            await refreshProfile();
          }
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error verifying payment:', err);
        setError('Failed to verify your subscription. Your account will be updated when payment is confirmed.');
        setLoading(false);
      }
    };

    verifyPayment();
  }, [user, location, navigate, refreshProfile]);

  const handleContinue = async () => {
    // Show loading state on the button
    setLoading(true);
    
    try {
      // First, check if we need to force sync the subscription status
      if (user && subscription && subscription.id) {
        const userDocRef = doc(db, 'users', user.uid);
        
        // Directly verify the subscription with Stripe
        const response = await axios.get(
          `${apiUrl}/api/verify-subscription/${subscription.id}`, 
          {
            headers: {
              'Authorization': `Bearer ${await user.getIdToken()}`
            }
          }
        );
        
        if (response.data.status === 'active') {
          // Directly update the user document
          await updateDoc(userDocRef, {
            subscriptionStatus: 'active',
            setupComplete: true,
            lastUpdated: new Date().toISOString()
          });
        }
      }
      
      // Refresh the profile one more time before navigating
      if (refreshProfile) {
        await refreshProfile();
      }
    } catch (error) {
      console.error('Error syncing subscription:', error);
      // Continue with navigation even if sync fails
    } finally {
      setLoading(false);
      // Navigate to home page
      navigate('/');
    }
  };

  if (loading) {
    return (
      <div className="payment-container">
        <h1>Confirming your subscription...</h1>
        <div className="loading-spinner"></div>
        <p>Please wait while we verify your payment</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="payment-container">
        <h1>Payment Verification</h1>
        <div className="payment-error">{error}</div>
        <p>Your subscription may still be processing. You can check your account status later.</p>
        <button 
          className="payment-submit-btn" 
          onClick={handleContinue}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Continue to Home Page'}
        </button>
      </div>
    );
  }

  return (
    <div className="payment-container">
      <h1 className="payment-title">Payment Successful!</h1>
      <div className="payment-success-message">
        <div className="success-icon">✓</div>
        <p>Your subscription has been activated successfully.</p>
        {subscription && (
          <div className="subscription-details">
            <p><strong>Plan:</strong> {subscription.planName}</p>
            <p><strong>Status:</strong> Active</p>
            <p><strong>Next billing date:</strong> {new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
          </div>
        )}
      </div>
      <button className="payment-submit-btn" onClick={handleContinue}>
        Continue to Home Page
      </button>
    </div>
  );
};

export default PaymentSuccess;