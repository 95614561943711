// user_context.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { app } from '../firebase-config';

// Create the context with a default value that matches the shape we expect
export const UserContext = createContext({
  user: null,
  profile: null,
  loading: true,
  refreshProfile: () => {} // Add this new function to the default context
});

// Custom hook for using the UserContext
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const auth = getAuth(app);
  const db = getFirestore(app);

  // Add this function to fetch the user profile
  const fetchUserProfile = async (user) => {
    if (!user) return null;
    
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        console.log('No user profile found');
        return null;
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  };

  // Add this function to refresh the profile
  const refreshProfile = async () => {
    if (!currentUser) return;
    
    try {
      const profile = await fetchUserProfile(currentUser);
      setUserProfile(profile);
      return profile;
    } catch (error) {
      console.error('Error refreshing profile:', error);
      return null;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // User is signed in
          setCurrentUser(user);
          
          // Fetch additional user data from Firestore
          const profile = await fetchUserProfile(user);
          setUserProfile(profile);
        } else {
          // User is signed out
          setCurrentUser(null);
          setUserProfile(null);
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        setCurrentUser(null);
        setUserProfile(null);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  // Create the value object with the same shape as the default context
  const value = {
    user: currentUser,
    profile: userProfile,
    loading,
    refreshProfile // Add the refreshProfile function to the context value
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};