import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { UserContext } from './user_context';
import { CreditCard, CheckCircle, ArrowLeft, Lock } from 'lucide-react';
import axios from 'axios';
import './payment.css';

// Display prices
const PLAN_AMOUNTS = {
  creator: '$15/month',
  professional: '$30/month',
};

// Use environment variable for API URL
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Main Payment component
const Payment = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [planInfo, setPlanInfo] = useState({
    name: '',
    price: '',
    isNewUser: false
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Setup plan info when component loads
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    if (!location.state?.plan) {
      navigate('/select-plan');
      return;
    }

    const planName = location.state.plan.toLowerCase();
    const isNewUser = location.state.isNewUser || false;
    
    // Format plan details
    let displayName;
    switch(planName) {
      case 'creator':
        displayName = 'Creator';
        break;
      case 'professional':
        displayName = 'Professional';
        break;
      default:
        displayName = planName.charAt(0).toUpperCase() + planName.slice(1);
    }
    
    setPlanInfo({
      name: displayName,
      price: PLAN_AMOUNTS[planName] || 'Price not available',
      isNewUser,
      planKey: planName
    });
    
    // Document title
    document.title = `Subscribe to ${displayName} Plan - Clickr8`;
    
  }, [user, location, navigate]);

  // Handle Stripe checkout
  const handleCheckout = async () => {
    setLoading(true);
    setError('');
    
    try {
      // First, update user document
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, {
          subscriptionPlan: planInfo.planKey,
          subscriptionStatus: 'pending',
          checkoutStarted: true,
          checkoutStartTime: new Date().toISOString()
        });
      }
      
      // Create a checkout session through your backend
      const response = await axios.post(`${apiUrl}/api/create-checkout-session`, {
        planKey: planInfo.planKey,
        userId: user.uid
      }, {
        headers: {
          'Authorization': `Bearer ${await user.getIdToken()}`,
          'Origin': window.location.origin
        }
      });
      
      // Check if we got a valid checkout URL
      if (response.data && response.data.checkoutUrl) {
        // Redirect to the checkout URL returned by your backend
        window.location.href = response.data.checkoutUrl;
      } else {
        throw new Error('Invalid checkout response from server');
      }
      
    } catch (err) {
      console.error('Error starting checkout:', err);
      setError('Failed to start checkout process. Please try again.');
      setLoading(false);
    }
  };
  
  const handleGoBack = async () => {
    try {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, {
          subscriptionPlan: null,
          setupComplete: false
        });
      }
      navigate('/select-plan');
    } catch (err) {
      console.error('Error handling back navigation:', err);
      setError('Unable to return to plan selection. Please try again.');
    }
  };
  
  if (!planInfo.name) {
    return (
      <div className="payment-container">
        <div className="loading-spinner"></div>
        <p className="loading-text">Loading plan details...</p>
      </div>
    );
  }
  
  return (
    <div className="payment-container">
      <div className="payment-card">
        <div className="payment-header">
          <h1>Complete Your Subscription</h1>
          <p className="subtitle">You're just one step away from unlocking premium features</p>
        </div>
        
        <div className="payment-summary">
          <h2>
            <CheckCircle className="summary-icon" />
            Order Summary
          </h2>
          <div className="plan-details">
            <div className="plan-name">{planInfo.name} Plan</div>
            <div className="plan-price">{planInfo.price}</div>
          </div>
          
          <ul className="plan-features">
            {planInfo.planKey === 'creator' && (
              <>
                <li>Up to 10 optimizations per day</li>
                <li>Basic video analytics</li>
                <li>Email support</li>
              </>
            )}
            {planInfo.planKey === 'professional' && (
              <>
                <li>Unlimited optimizations</li>
                <li>Advanced video analytics</li>
                <li>Priority support</li>
                <li>Custom keyword suggestions</li>
              </>
            )}
          </ul>
          
          <div className="billing-info">
            <span className="info-badge">Monthly Billing</span>
            Cancel anytime with no penalty
          </div>
        </div>
        
        <div className="checkout-section">
          <button 
            className="payment-submit-btn"
            onClick={handleCheckout}
            disabled={loading}
          >
            {loading ? (
              <>
                <div className="btn-spinner"></div>
                Processing...
              </>
            ) : (
              <>
                <CreditCard className="btn-icon" />
                Checkout with Stripe
              </>
            )}
          </button>
          
          {error && <div className="payment-error">{error}</div>}
          
          <div className="payment-security">
            <Lock className="security-icon" />
            <p>Your payment information is secure and encrypted with Stripe</p>
          </div>
        </div>
        
        <button 
          className="payment-back-btn"
          onClick={handleGoBack}
          disabled={loading}
        >
          <ArrowLeft className="back-icon" />
          Back to Plan Selection
        </button>
      </div>
    </div>
  );
};

export default Payment;