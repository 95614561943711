import React, { useState, useEffect, useRef } from 'react';
import { Search } from 'lucide-react';
import VideoCard from './video_card';
import SearchResults from './search_results';
import { TYPING_SPEED, DISPLAY_DURATION, FADE_DURATION } from '../utils/constants';
import { useNavigate } from "react-router-dom";
import { formatDuration } from '../utils/video_utils.js';
import './animated_search.css';

const SearchCache = class {
  constructor(maxEntries = 5, expirationTime = 2 * 60 * 60 * 1000) {
    this.cache = new Map();
    this.maxEntries = maxEntries;
    this.expirationTime = expirationTime;
  }

  set(key, value) {
    if (this.cache.size >= this.maxEntries) {
      const oldestKey = this.cache.keys().next().value;
      this.cache.delete(oldestKey);
    }

    this.cache.set(key, {
      value,
      timestamp: Date.now()
    });
  }

  get(key) {
    const entry = this.cache.get(key);
    
    if (!entry || (Date.now() - entry.timestamp > this.expirationTime)) {
      this.cache.delete(key);
      return null;
    }

    return entry.value;
  }

  has(key) {
    const entry = this.cache.get(key);
    if (!entry || (Date.now() - entry.timestamp > this.expirationTime)) {
      this.cache.delete(key);
      return false;
    }
    return true;
  }
};

const searchResultsCache = new SearchCache();

const SearchDemo = ({ onSearchStart }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isVisible] = useState(true);
  const [isResultsVisible, setIsResultsVisible] = useState(true);
  const [trendingTerms, setTrendingTerms] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [apiBaseUrl, setApiBaseUrl] = useState('');
  const inputRef = useRef(null);
  const cursorRef = useRef(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    // Set the API base URL from environment variable or use fallback
    const apiUrl = process.env.REACT_APP_API_URL || 'https://clickr8.onrender.com';
    setApiBaseUrl(apiUrl);
    console.log("Using API base URL:", apiUrl);
    
    const categoryNames = {
        '1': 'Film & Animation',
        '2': 'Autos & Vehicles',
        '10': 'Music',
        '20': 'Gaming',
        '23': 'Comedy',
        '24': 'Entertainment',
    };
    setTrendingTerms(Object.values(categoryNames));
  }, []);

  const handleFocus = async () => {
    setIsPaused(true);
    setIsFocused(true);
    
    // Clear any ongoing animation immediately
    if (displayedText) {
        setDisplayedText('');
        if (inputRef.current) {
            inputRef.current.style.setProperty('--text-width', '0px');
        }
    }
};

const handleBlur = () => {
    setIsFocused(false);
    if (!displayedText) {
        setIsPaused(false);
    }
};

// Updated cursor position calculation with adjustment for better alignment
useEffect(() => {
  if (inputRef.current) {
      const computeTextWidth = (text) => {
          const computedStyle = window.getComputedStyle(inputRef.current);
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          context.font = `${computedStyle.fontSize} ${computedStyle.fontFamily}`;
          return context.measureText(text).width;
      };
      
      // Get the input's padding
      const computedStyle = window.getComputedStyle(inputRef.current);
      const paddingLeft = parseFloat(computedStyle.paddingLeft);
      const fontSize = parseFloat(computedStyle.fontSize);
      
      // Average character width for adjustment (approximately half a character)
      const charWidthAdjustment = fontSize * 0.65;
      
      // Calculate the position based on text content
      let position;
      if (displayedText) {
          // Position after the last character of the typed text with adjustment
          const textWidth = computeTextWidth(displayedText);
          position = paddingLeft + textWidth + charWidthAdjustment;
      } else {
          // Position at the beginning, right at the placeholder text start with adjustment
          position = paddingLeft + charWidthAdjustment;
      }
      
      setCursorPosition(position);
  }
}, [displayedText]);

useEffect(() => {
    if (!trendingTerms.length || isPaused) return;
    
    let isAnimationRunning = true;
    
    const animateSearch = async () => {
        const term = trendingTerms[currentIndex];
        setIsTyping(true);

        // Type the text
        for (let i = 0; i <= term.length && isAnimationRunning; i++) {
            if (isPaused) return;
            await new Promise(resolve => setTimeout(resolve, TYPING_SPEED));
            setDisplayedText(term.substring(0, i));
        }

        if (isPaused || !isAnimationRunning) return;
        await performSearch(term);

        await new Promise(resolve => setTimeout(resolve, DISPLAY_DURATION));

        if (isPaused || !isAnimationRunning) return;
        
        // Untype the text
        for (let i = term.length; i >= 0 && isAnimationRunning; i--) {
            if (isPaused) return;
            await new Promise(resolve => setTimeout(resolve, TYPING_SPEED / 2));
            setDisplayedText(term.substring(0, i));
        }

        if (isPaused || !isAnimationRunning) return;
        setIsResultsVisible(false);
        await new Promise(resolve => setTimeout(resolve, FADE_DURATION));

        setIsTyping(false);
        setCurrentIndex((prev) => (prev + 1) % trendingTerms.length);
    };

    const timeoutId = setTimeout(animateSearch, FADE_DURATION);

    return () => {
        isAnimationRunning = false;
        clearTimeout(timeoutId);
    };
}, [currentIndex, trendingTerms, isPaused]);
  

    const performSearch = async (term) => {
        try {
            setIsResultsVisible(false);
            await new Promise(resolve => setTimeout(resolve, FADE_DURATION));

            const cachedResults = searchResultsCache.get(term);
            if (cachedResults) {
                setSearchResults(cachedResults);
                setIsResultsVisible(true);
                return;
            }

            // Map the term to the corresponding category ID
            let categoryId = 10; // Default to Music (10)
            
            // Map search terms to category IDs
            const categoryMapping = {
                'Music': '10',
                'Gaming': '20',
                'Film & Animation': '1',
                'Autos & Vehicles': '2',
                'Comedy': '23',
                'Entertainment': '24'
            };
            
            // Check if the current term matches any category name
            if (categoryMapping[term]) {
                categoryId = categoryMapping[term];
            }

            // Use the apiBaseUrl instead of relative path
            const url = `${apiBaseUrl}/api/fetch-videos?categoryId=${categoryId}&maxResults=3`;
            console.log("Fetching from:", url);
            
            // Include CORS headers in the request
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                // Include credentials if your API requires authentication
                // credentials: 'include',
            });
            
            // Check if the response is OK
            if (!response.ok) {
                const errorText = await response.text();
                console.error('API Error:', response.status, errorText);
                throw new Error(`API error: ${response.status} ${errorText}`);
            }
            
            const data = await response.json();
            console.log("API Response:", data);

            // Map the YouTube API response to match VideoCard props
            const formattedVideos = data.map(video => ({
                title: video.snippet.title,
                thumbnail: video.snippet.thumbnails.medium.url,
                views: parseInt(video.statistics.viewCount),
                likes: parseInt(video.statistics.likeCount),
                duration: video.contentDetails.duration,
                channelName: video.snippet.channelTitle,
                videoId: video.id
            }));

            searchResultsCache.set(term, formattedVideos);
            setSearchResults(formattedVideos);
            setIsResultsVisible(true);
        } catch (error) {
            console.error('Error searching videos:', error);
            // Set empty results to prevent UI from waiting indefinitely
            setSearchResults([]);
            setIsResultsVisible(true);
        }
    };


  const handleSearchResults = (results) => {
    if (onSearchStart) {
      onSearchStart();
    }
    // Navigate to search results page with both results and query
    // Format durations for all results before navigating
    const resultsWithFormattedDurations = results.map(video => ({
        ...video,
        formattedDuration: formatDuration(video.duration)
    }));
    
    navigate('/search', { 
      state: { 
          searchResults: resultsWithFormattedDurations,
          searchQuery: displayedText // Add the current search term
      } 
    });
  };

  const resetSearch = () => {
    setIsSearching(false);
    setSearchResults([]);
    // Reset any other necessary states
    setCurrentIndex(0);
    setDisplayedText('');
    setIsPaused(false);
  };

  if (isSearching) {
    return <SearchResults results={searchResults} onBack={resetSearch} />;
  }

  return (
        <div className="search-demo">
            <form onSubmit={(e) => { 
                e.preventDefault(); 
                if (displayedText.trim()) {
                    // Use the apiBaseUrl for the search API endpoint
                    const searchUrl = `${apiBaseUrl}/api/search?q=${encodeURIComponent(displayedText)}`;
                    console.log("Searching with URL:", searchUrl);
                    
                    fetch(searchUrl, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        // Include credentials if your API requires authentication
                        // credentials: 'include',
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`API error: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then(data => {
                            // Format the data for the VideoCard component
                            const formattedResults = data.map(video => ({
                                title: video.title,
                                thumbnail: video.thumbnail,
                                views: parseInt(video.views),
                                likes: parseInt(video.likes),
                                duration: video.duration,
                                channelName: video.channelTitle,
                                videoId: video.id
                            }));
                            
                            // Navigate to search results page
                            handleSearchResults(formattedResults);
                        })
                        .catch(error => {
                            console.error('Error searching videos:', error);
                        });
                }
            }} className="search-form search-form--demo">
                <div className="search-bar-container">
                    <input
                        ref={inputRef}
                        type="text"
                        value={displayedText}
                        onChange={(e) => setDisplayedText(e.target.value)}
                        className="search-input search-input--demo"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder="Search a Video Topic..."
                        data-term-index={currentIndex}
                    />
                    {!isFocused && (
                        <div 
                            ref={cursorRef}
                            className="typing-cursor"
                            style={{ 
                                left: `${cursorPosition}px`,
                                opacity: isTyping || !isFocused ? 1 : 0 
                            }}
                        />
                    )}

                </div>
                <button
                    type="submit"
                    className="search-button"
                    disabled={!displayedText.trim()}
                >
                    <Search className="search-icon" />
                </button>
            </form>

            <div 
                className={`video-results ${isVisible ? 'video-results--visible' : ''} ${isResultsVisible ? 'video-results--results-visible' : ''}`}
            >
                {searchResults.map((video, index) => (
                    <VideoCard
                        key={`${video.videoId}-${index}`}
                        {...video}
                    />
                ))}
            </div>
        </div>
    );
};

export default SearchDemo;