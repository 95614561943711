// top_nav_bar.jsx
import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './top_nav_bar.css';
import SearchDemo from './animated_search.jsx';

const TopNavBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const [showDemo, setShowDemo] = useState(true);
  const navigate = useNavigate();

  const API_BASE_URL = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:5000' 
    : '';

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    setIsSearching(true);
    setError(null);
    setShowDemo(false);

    try {
      const response = await fetch(`${API_BASE_URL}/api/search?q=${encodeURIComponent(searchQuery)}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Update navigation to include searchQuery
      navigate('/search', { 
        state: { 
          searchResults: data,
          searchQuery: searchQuery // Add the search query to the navigation state
        } 
      });
    } catch (error) {
      console.error('Error searching videos:', error);
      setError(error.message);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="nav-container">
      <div className="nav-content">
        <div className='tagline-container'>
          <div className="tagline">Improve Your <span className="highlight">Click-Through Rate</span></div>
          <h2 className='motto'>AI that takes the guesswork out of marketing</h2>
        </div>
        
        {showDemo ? (
          <SearchDemo 
            onSearchStart={() => setShowDemo(false)}
            navigate={navigate}
          />
        ) : (
          <form onSubmit={handleSearch} className="search-form">
            <div className="search-bar-container">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search a Video Topic..."
                className="search-input"
                disabled={isSearching}
              />
              {error && <div className="error-message">{error}</div>}
            </div>
            <button
              type="submit"
              className={`search-button ${isSearching ? 'searching' : ''}`}
              disabled={isSearching}
            >
              {isSearching ? (
                <span className="loading-container">
                  <span className="spinner"></span>
                </span>
              ) : (
                <Search className="search-icon" />
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default TopNavBar;