import "./about.css";

const About = () => {
    return(
        <div className="about-container">
            <div className="about-section">
                <img src="images/youtube_logo.png" alt="AI analyzing YouTube videos" className="about-image"/>
                <div className="about-text">
                    <h2 className="about-header">YouTube Video Optimization Tool</h2>
                    <p className="about-description">
                        Our tool is built for creators who want to stand out on YouTube. With millions of videos uploaded daily, your title and thumbnail are crucial in grabbing attention. ClickThrough helps you optimize these elements to maximize clicks.
                    </p>
                </div>
            </div>

            <div className="about-section reverse">
                <img src="images/better_network.png" alt="Creator brainstorming title ideas" className="about-image"/>
                <div className="about-text">
                    <h2 className="about-header">Powered by AI</h2>
                    <p className="about-description">
                        Using advanced AI and NLP, ClickThrough analyzes top-performing videos in your category and provides insights on how to make your content more compelling. Say goodbye to guesswork and start optimizing with data-driven suggestions.
                    </p>
                </div>
            </div>

            <div className="about-section">
                <img src="images/results.png" alt="User interacting with AI tool" className="about-image"/>
                <div className="about-text">
                    <h2 className="about-header">Simple to Use</h2>
                    <p className="about-description">
                        Getting started is easy! Just enter a search term, input your title idea, and upload a thumbnail. Our AI will assess them, give you a score, and offer suggestions to refine your content. Iterate until you're satisfied with the results!
                    </p>
                </div>
            </div>
        </div>

    );
};

export default About;