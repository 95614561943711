import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { UserContext } from './user_context';
import PlanSelection from './plan_selection';
import './select_plan.css';

const SelectPlan = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedPlan, setSelectedPlan] = useState('Basic');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [fromGoogle, setFromGoogle] = useState(false);

    useEffect(() => {
        // Verify user is logged in
        if (user === undefined) return;
        
        if (!user) {
            navigate('/auth');
            return;
        }

        // Check if user is coming from Google sign-in
        if (location.state?.fromGoogle) {
            setFromGoogle(true);
        }
        
        // Setup user profile
        const setupUserProfile = async () => {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                
                // If the user has a subscription plan and setup is complete,
                // they shouldn't be on this page - redirect to home
                if (userDoc.exists() && 
                    userDoc.data().subscriptionPlan && 
                    userDoc.data().setupComplete) {
                    navigate('/');
                    return;
                }
                
                // If coming back from payment, the previous plan might be in Firestore
                // We should load it as the default selection
                if (userDoc.exists() && userDoc.data().subscriptionPlan) {
                    const planName = userDoc.data().subscriptionPlan;
                    setSelectedPlan(planName.charAt(0).toUpperCase() + planName.slice(1));
                }
                
                setLoading(false);
            } catch (err) {
                console.error('Error setting up user profile:', err);
                setError('Failed to verify account status.');
                setLoading(false);
            }
        };

        setupUserProfile();
    }, [user, navigate, location]);

    const handlePlanSelect = (planName) => {
        setSelectedPlan(planName);
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError('');
        
        try {
            const subscriptionPlan = selectedPlan.toLowerCase();
            
            if (subscriptionPlan === 'basic') {
                // For basic plan, mark setup as complete immediately
                await updateDoc(doc(db, 'users', user.uid), {
                    subscriptionPlan: subscriptionPlan,
                    setupComplete: true
                });
                navigate('/');
            } else {
                // For paid plans, only update the plan and redirect to payment
                await updateDoc(doc(db, 'users', user.uid), {
                    subscriptionPlan: subscriptionPlan,
                    setupComplete: false // Keep as false until payment is complete
                });
                navigate('/payment', { 
                    state: { 
                        plan: subscriptionPlan,
                        isNewUser: true
                    } 
                });
            }
        } catch (err) {
            console.error('Error updating plan:', err);
            setError('Failed to update subscription plan. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className="select-plan-container">
            <div className="select-plan-box">
                <div className="select-plan-header">
                    <h2>Choose Your Plan</h2>
                    {fromGoogle && (
                        <div className="google-info">
                            <p>Complete your Google sign-in by selecting a plan</p>
                        </div>
                    )}
                </div>
                
                <PlanSelection 
                    onPlanSelect={handlePlanSelect} 
                    selectedPlan={selectedPlan} 
                />
                
                {error && <div className="error-message">{error}</div>}
                
                <button 
                    onClick={handleSubmit} 
                    className="plan-submit-button"
                    disabled={loading}
                >
                    {loading ? 'Processing...' : `Continue with ${selectedPlan} Plan`}
                </button>
            </div>
        </div>
    );
};

export default SelectPlan;