export function formatDuration(duration) {
    // If no duration provided, return default
    if (!duration) return "0:00";

    try {
        // Try to match the YouTube duration format (PT#H#M#S)
        const match = duration.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/);
        
        // If no match found, return default
        if (!match) return "0:00";

        const hours = parseInt(match[1] || '0');
        const minutes = parseInt(match[2] || '0');
        const seconds = parseInt(match[3] || '0');

        // Handle hours if present
        if (hours > 0) {
            return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }

        // No hours, just minutes and seconds
        return `${minutes}:${String(seconds).padStart(2, '0')}`;
    } catch (error) {
        console.warn('Error formatting duration:', duration);
        return "0:00";
    }
}