// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import TopNavBar from './components/top_nav_bar';
import SearchResults from './components/search_results';
import TopMenu from './components/top_menu';
import About from './components/about';
import Auth from './components/auth';
import Pricing from './components/pricing';
import Payment from './components/payment';
import { UserProvider } from './components/user_context';
import SelectPlan from './components/select_plan';
import { useUser } from './components/user_context';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase-config'; // Ensure this path is correct
import PaymentSuccess from './components/payment_success';
import './App.css';



// HomePage component for the main landing page
const HomePage = () => {
    return (
        <>
            <div className="hero-section">
                <TopNavBar />
                
                {/* TopNavBar already contains the tagline, motto, and search components */}
            </div>
            
            <div className="about-container">
                <About />
            </div>
            
            {/* <main className="py-4">
                {Object.entries(categoryNames).map(([catId, name]) => (
                    <section key={catId} className="mb-8">
                        <VideoCarousel 
                            title={name}
                            videos={videosByCategory[catId] || []}
                        />
                    </section>
                ))}
                
                {Object.keys(videosByCategory).length === 0 && (
                    <div className="text-center text-gray-400 mt-8">
                        Loading videos...
                    </div>
                )}
            </main> */}
        </>
    );
};

// Clean SearchResultsPage component

const AppLayout = () => {
    const location = useLocation();
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    useEffect(() => {
        // Don't do anything until we know the user's auth status
        if (user === undefined) return;
        
        const checkUserStatus = async () => {
            try {
                // Skip redirects for payment success page
                if (location.pathname === '/payment-success') {
                    setLoading(false);
                    return;
                }
                
                // User is logged in - check their subscription status
                if (user) {
                    const userDoc = await getDoc(doc(db, 'users', user.uid));
                    
                    // If user has completed setup, let them access any page
                    if (userDoc.exists() && userDoc.data().setupComplete) {
                        setLoading(false);
                        return;
                    }
                    
                    // For new users or users without subscription plan
                    if (!userDoc.exists() || !userDoc.data().subscriptionPlan) {
                        // Only redirect if not already on select-plan page
                        if (location.pathname !== '/select-plan') {
                            navigate('/select-plan', { replace: true });
                        }
                    } 
                    // For users with non-basic plans that haven't completed payment
                    else if (userDoc.data().subscriptionPlan !== 'basic' && !userDoc.data().setupComplete) {
                        // Special case: Allow navigation back to select-plan from payment
                        // Only redirect if not already on payment page AND not trying to go to select-plan
                        if (location.pathname !== '/payment' && location.pathname !== '/select-plan') {
                            navigate('/payment', { 
                                state: { 
                                    plan: userDoc.data().subscriptionPlan, 
                                    isNewUser: false 
                                },
                                replace: true
                            });
                        }
                    }
                } 
                // No user logged in - let them access public pages
                else {
                    // Optional: Redirect from protected pages if not logged in
                    const protectedPaths = ['/select-plan', '/payment', '/payment-success'];
                    if (protectedPaths.includes(location.pathname)) {
                        navigate('/auth', { replace: true });
                    }
                }
            } catch (error) {
                console.error('Error checking user status:', error);
            } finally {
                setLoading(false);
            }
        };
        
        checkUserStatus();
    }, [user, navigate, location.pathname]);

    if (loading && user !== undefined) return <div>Loading...</div>;

    return (
        <div className="full-screen">
            <TopMenu />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/search" element={<SearchResults/>} />
                <Route path="/auth" element={<Auth />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/select-plan" element={<SelectPlan />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
            </Routes>
        </div>
    );
};
  
function App() {
    return (
      <BrowserRouter>
        <UserProvider>
          <AppLayout />
        </UserProvider>
      </BrowserRouter>
    );
}

export default App;