import React from 'react';
import './video_card.css';

// Add the formatDuration function 
export function formatDuration(duration) {
    // If no duration provided, return default
    if (!duration) return "0:00";

    try {
        // Try to match the YouTube duration format (PT#H#M#S)
        const match = duration.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/);
        
        // If no match found, return default
        if (!match) return "0:00";

        const hours = parseInt(match[1] || '0');
        const minutes = parseInt(match[2] || '0');
        const seconds = parseInt(match[3] || '0');

        // Handle hours if present
        if (hours > 0) {
            return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }

        // No hours, just minutes and seconds
        return `${minutes}:${String(seconds).padStart(2, '0')}`;
    } catch (error) {
        console.warn('Error formatting duration:', duration);
        return "0:00";
    }
}

const VideoCard = ({ 
  title,
  thumbnail,
  views = 0,
  likes = 0,
  duration = "0:00",
  channelName = "",
  videoId 
}) => {
  const handleClick = () => {
    window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank', 'noopener,noreferrer');
  };

  // Use formatDuration to properly format the duration
  const formattedDuration = typeof duration === 'string' && duration.startsWith('PT') 
    ? formatDuration(duration) 
    : duration;

  return (
    <div 
      className="video-card"
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
      role="button"
      tabIndex={0}
      aria-label={`Watch ${title}`}
    >
      <div className="video-card__thumbnail-container">
        <img
          src={thumbnail || "/api/placeholder/320/180"}
          alt={title}
          className="video-card__thumbnail"
        />
        <div className="video-card__duration">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="12" 
            height="12" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="video-card__duration-icon"
          >
            <circle cx="12" cy="12" r="10"/>
            <polyline points="12 6 12 12 16 14"/>
          </svg>
          {formattedDuration}
        </div>
      </div>
      
      <div className="video-card__content">
        <h3 className="video-card__title">
          {title || "Video Title"}
        </h3>
        
        {channelName && (
          <p className="video-card__channel">
            {channelName}
          </p>
        )}
        
        <div className="video-card__stats">
          <span className="video-card__stat">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="16" 
              height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              className="video-card__icon"
            >
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
              <circle cx="12" cy="12" r="3" />
            </svg>
            {views.toLocaleString()}
          </span>
          <span className="video-card__stat">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="16" 
              height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              className="video-card__icon"
            >
              <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
            </svg>
            {likes.toLocaleString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;