import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import VideoCarousel from './video_carousel';
import TitleOptimizer from './title_optimizer';
import { formatDuration } from '../utils/video_utils.js';
import './search_results.css';

const SearchResults = () => {
  const location = useLocation();
  const { searchResults, searchQuery } = location.state || { searchResults: [], searchQuery: '' };
  const optimizerRef = useRef(null);
  const resultsListRef = useRef(null);

  useEffect(() => {
    const updateResultsHeight = () => {
      if (window.innerWidth > 768 && optimizerRef.current && resultsListRef.current) {
        const optimizerHeight = optimizerRef.current.offsetHeight;
        resultsListRef.current.style.height = `${optimizerHeight}px`;
      } else if (resultsListRef.current) {
        resultsListRef.current.style.height = 'auto';
      }
    };

    updateResultsHeight();
    window.addEventListener('resize', updateResultsHeight);
    return () => window.removeEventListener('resize', updateResultsHeight);
  }, []);

  if (!searchResults || searchResults.length === 0) {
    return (
      <div className="search-results__empty">
        No videos found matching your search.
      </div>
    );
  }

  const carouselVideos = searchResults.map(video => ({
    videoId: video.id,
    title: video.title,
    thumbnail: video.thumbnail,
    channelName: video.channelName,
    views: parseInt(video.views) || 0,
    likes: parseInt(video.likes) || 0,
    duration: formatDuration(video.duration)
  }));

  return (
    <div className="search-results">
      <div className='results-descriptor'> 
        <h2>Search results for "{searchQuery}"</h2>
      </div>
      <div className="video-carousel-section">
        <VideoCarousel 
          videos={carouselVideos}
          scrollAmount={400}
        />
      </div>

      <div className='optimizer-label'> 
        <h1>Title Optimizer</h1>
      </div>
      <div className="title-optimizer-section">
        <TitleOptimizer searchTerm={searchResults.map(result => result.title).join(' ')} />
      </div>
    </div>
  );
};

export default SearchResults;