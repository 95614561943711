import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import VideoCard from './video_card';
import './video_carousel.css';

const VideoCarousel = ({ title, videos = [], scrollAmount: initialScrollAmount = 300 }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const trackRef = useRef(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [visibleItems, setVisibleItems] = useState(3);
  const [scrollAmount, setScrollAmount] = useState(initialScrollAmount);

  // Calculate how many items should be visible based on screen width
  useEffect(() => {
    const calculateVisibleItems = () => {
      const width = window.innerWidth;
      if (width < 480) {
        setVisibleItems(1); // Mobile phones
        setScrollAmount(width * 0.8); // Scroll almost full width on mobile
      } else if (width < 768) {
        setVisibleItems(2); // Larger mobiles/small tablets
        setScrollAmount(width * 0.5); // Scroll half-width on tablets
      } else if (width < 1024) {
        setVisibleItems(3); // Tablets
        setScrollAmount(350); // Scroll more than one card
      } else {
        setVisibleItems(3); // Desktops and large screens
        setScrollAmount(initialScrollAmount); // Use the initial scroll amount
      }
    };

    calculateVisibleItems();
    window.addEventListener('resize', calculateVisibleItems);
    
    return () => {
      window.removeEventListener('resize', calculateVisibleItems);
    };
  }, [initialScrollAmount]);

  // Update navigation buttons whenever component renders or screen resizes
  useEffect(() => {
    updateNavButtons(scrollPosition);
    
    const handleResize = () => {
      updateNavButtons(scrollPosition);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scrollPosition]);

  const updateNavButtons = (newPosition) => {
    if (!trackRef.current) return;
    
    const { scrollWidth, clientWidth } = trackRef.current;
    setShowPrev(newPosition > 0);
    setShowNext(newPosition < scrollWidth - clientWidth);
  };

  const handleScroll = (direction) => {
    if (!trackRef.current) return;

    const newPosition = direction === 'next' 
      ? scrollPosition + scrollAmount 
      : scrollPosition - scrollAmount;

    // Ensure we don't scroll past the bounds
    const maxScroll = trackRef.current.scrollWidth - trackRef.current.clientWidth;
    const boundedPosition = Math.max(0, Math.min(newPosition, maxScroll));

    setScrollPosition(boundedPosition);
    trackRef.current.style.transform = `translateX(-${boundedPosition}px)`;
    updateNavButtons(boundedPosition);
  };

  return (
    <div className="video-carousel">
      {title && <h2 className="video-carousel__title">{title}</h2>}
      <div className='video-carousel-contents'>
        <div className="video-carousel__container">
          {showPrev && (
            <button 
              className="video-carousel__nav-button video-carousel__nav-button--prev"
              onClick={() => handleScroll('prev')}
              aria-label="Previous videos"
            >
              <ChevronLeft size={24} />
            </button>
          )}

          <div className="video-carousel__track" ref={trackRef}>
            {videos.map((video, index) => (
              <div key={video.videoId || index} className="video-carousel__item">
                <VideoCard {...video} />
              </div>
            ))}
          </div>

          {showNext && (
            <button 
              className="video-carousel__nav-button video-carousel__nav-button--next"
              onClick={() => handleScroll('next')}
              aria-label="Next videos"
            >
              <ChevronRight size={24} />
            </button>
          )}
        </div>
      </div> 
      <div className="cat-divider"></div>
    </div>
  );
};

export default VideoCarousel;