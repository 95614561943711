import React, { useState } from 'react';
import './plan_selection.css';

// Import plan data from pricing.jsx
import { plans } from './pricing';

const PlanSelection = ({ onPlanSelect, selectedPlan = "Basic" }) => {
  const [selected, setSelected] = useState(selectedPlan);

  const handlePlanSelect = (planName) => {
    setSelected(planName);
    onPlanSelect(planName);
  };

  return (
    <div className="plan-selection-container">
      <div className="plan-options-horizontal">
        {plans.map((plan) => (
          <div 
            key={plan.name}
            className={`plan-card ${selected === plan.name ? 'selected' : ''}`}
            onClick={() => handlePlanSelect(plan.name)}
          >
            <div className="plan-card-header">
              <h3 className="plan-name">{plan.name}</h3>
              {plan.tag && <span className="plan-tag">{plan.tag}</span>}
            </div>
            
            <div className="plan-price">
              <span className="price">{plan.price}</span>
              <span className="duration">{plan.duration}</span>
            </div>
            
            <ul className="plan-features">
              {plan.features.slice(0, 4).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanSelection;