import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Loader2, X, AlertTriangle } from 'lucide-react';
import { UserContext } from './user_context'; // Adjust the import path as needed
import './title_optimizer.css';

const TitleOptimizer = ({ searchTerm, isMobile }) => {
  const [title, setTitle] = useState('');
  const [optimization, setOptimization] = useState({ overall_score: 0, details: {}, suggested_keywords: [] });
  const [animatedScores, setAnimatedScores] = useState({
    overall_score: 0,
    details: {
      "Semantic Similarity": 0,
      "Keyword Density": 0,
      "Length Optimization" : 0,
      "Clickbait Potential": 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [error, setError] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);

  // Get user context
  const { user } = useContext(UserContext);

  const getScoreColor = (score) => {
    if (score >= 70) return '#48bb78';
    if (score >= 40) return '#ecc94b';
    return '#f56565';
  };

  const toggleKeyword = (keyword) => {
    if (selectedKeywords.includes(keyword)) {
      // Remove keyword
      setSelectedKeywords(selectedKeywords.filter(k => k !== keyword));
      setTitle((prevTitle) => {
        const regex = new RegExp(`\\s?${keyword}\\s?`);
        return prevTitle.replace(regex, ' ').trim();
      });
    } else {
      // Add keyword
      setSelectedKeywords([...selectedKeywords, keyword]);
      setTitle((prevTitle) => {
        const separator = prevTitle.trim() ? ' ' : '';
        return `${prevTitle.trim()}${separator}${keyword}`;
      });
    }
  };

  // Animate scores using a gradual increment
  useEffect(() => {
    if (optimization.overall_score > 0) {
      const duration = 1000;
      const steps = 60;
      const interval = duration / steps;
  
      let stepCount = 0;
      const targetOverall = optimization.overall_score;
      const targetDetails = { ...optimization.details };
  
      const intervalId = setInterval(() => {
        stepCount++;
  
        setAnimatedScores((prev) => {
          let newOverall = Math.min(prev.overall_score + targetOverall / steps, targetOverall);
  
          let newDetails = {};
          Object.keys(targetDetails).forEach((key) => {
            newDetails[key] = Math.min((prev.details[key] || 0) + targetDetails[key] / steps, targetDetails[key]);
          });
  
          return { overall_score: newOverall, details: newDetails };
        });
  
        if (stepCount >= steps) {
          clearInterval(intervalId);
        }
      }, interval);
  
      return () => clearInterval(intervalId);
    }
  }, [optimization]);

  // In your title_optimizer.jsx, modify the optimizeTitle function:

  const optimizeTitle = useCallback(async () => {
    if (!title || !searchTerm) return;
    
    console.log("Starting optimization for:", title);
    setError(null);
    setSubscriptionError(null);
    setLoading(true);
    
    try {
      // Check if user is authenticated
      if (!user) {
        throw new Error('Please log in to optimize titles');
      }

      console.log("Getting user token...");
      const token = await user.getIdToken(); // Get Firebase auth token

      const API_URL = process.env.REACT_APP_API_URL || 'https://clickr8.onrender.com';
      console.log("Sending request to API...", API_URL);
      
      const response = await fetch(`${API_URL}/api/optimize-title`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ title, searchTerm }),
      });

      console.log("Response received, status:", response.status);
      const data = await response.json();
      console.log("Response data:", data);
      
      if (!response.ok) {
        // Check if this is a limit error specifically
        if (data.error && (
          data.error.includes('limit reached') || 
          data.error.includes('Optimization limit')
        )) {
          // This is a subscription limit error
          setSubscriptionError({
            title: data.error || 'Subscription Limit Reached',
            message: data.message || 'Please upgrade your plan to continue using the title optimizer.',
            planType: user?.planType || 'basic'
          });
        } else {
          // Regular error
          throw new Error(data.error || data.message || 'Optimization failed');
        }
      } else {
        // Success case
        console.log("Processing successful response");
        setOptimization({
          overall_score: data.overall_score || 0,
          details: {
            "Semantic Similarity": data.details?.semantic_similarity || 0,
            "Keyword Density": data.details?.keyword_density || 0,
            "Length Optimization": data.details?.length_optimization || 0,
            "Clickbait Potential": data.details?.clickbait_assessment || 0,
          },
          suggested_keywords: data.suggested_keywords || [],
        });
        console.log("Optimization state updated");
      }  
    } catch (error) {
      console.error("Optimization error:", error);
      setError(error.message);
      
      // If the error is authentication-related, provide a login link
      if (error.message.includes('log in') || error.message.includes('Authentication required')) {
        setError('Please log in to use the title optimizer');
      }
      
      setOptimization({ overall_score: 0, details: {}, suggested_keywords: [] });
    } finally {
      console.log("Setting loading to false");
      setLoading(false);
    }
  }, [title, searchTerm, user]);
  
  const SubscriptionLimitAlert = () => {
    if (!subscriptionError) return null;
    
    return (
      <div className="subscription-limit-alert">
        <AlertTriangle className="limit-icon" />
        <div className="limit-content">
          <h4 className="limit-title">{subscriptionError.title}</h4>
          <p className="limit-message">{subscriptionError.message}</p>
          <div className="limit-actions">
            <a href="/pricing" className="upgrade-button">
              Upgrade to {subscriptionError.planType === 'basic' ? 'Pro' : 'Premium'}
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="title-optimizer-container">
      {!user && (
        <div className="error-message">
          <AlertTriangle className="error-icon" />
          <span>Please log in to use the title optimizer</span>
          <a href="/auth" className="login-link">Log In</a>
        </div>
      )}
      <SubscriptionLimitAlert />
      <div className="mb-4">
        <div className="title-optimizer-input-container">
          <input
            id="titleInput"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="title-optimizer-input"
            placeholder="Enter your video title..."
          />
          <button
            onClick={optimizeTitle}
            className="title-optimizer-button"
            disabled={loading}
          >
            {loading ? (
              <span className="loading-container">
                <Loader2 className="loading-icon" />
              </span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="up-arrow-icon"
              >
                <rect x="3" y="3" width="18" height="18" rx="4" />
                <path d="M12 16V8M8 12l4-4 4 4" />
              </svg>
            )}
          </button>
        </div>
      </div>
      
      {/* Suggested Keywords */}
      {optimization.suggested_keywords.length > 0 && (
        <div className="suggested-keywords">
          <h4>Suggested Keywords</h4>
          <div className="keywords-container">
            {optimization.suggested_keywords.map((keyword, index) => (
              <button
                key={index}
                onClick={() => toggleKeyword(keyword)}
                className={`keyword-suggestion ${selectedKeywords.includes(keyword) ? 'selected' : ''}`}
              >
                {keyword}
                {selectedKeywords.includes(keyword) && (
                  <X size={14} className="keyword-icon" />
                )}
              </button>
            ))}
          </div>
        </div>
      )}
      
      {/* Results Section */}
      {animatedScores && (
        <div className="mt-8">
          {/* Donut Chart */}
          <div className="donut-chart">
            <svg className="donut-chart-ring" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
              <circle
                className="donut-chart-background"
                cx="60"
                cy="60"
                r="50"
                fill="none"
                stroke="#e2e8f0"
                strokeWidth="10"
              />
              <circle
                className="donut-chart-progress"
                cx="60"
                cy="60"
                r="50"
                fill="none"
                stroke={getScoreColor(animatedScores.overall_score)}
                strokeWidth="10"
                strokeDasharray="314"
                strokeDashoffset={314 - (314 * animatedScores.overall_score) / 100}
                strokeLinecap="round"
                transform="rotate(-90 60 60)"
              />
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize="18px"
                fontWeight="bold"
                fill={getScoreColor(animatedScores.overall_score)}
              >
                {Math.round(animatedScores.overall_score)}%
              </text>
              <text
                x="50%"
                y="65%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize="10px"
                fill="white"
              >
                Overall Score
              </text>
            </svg>
          </div>

          {/* Metrics */}
          <div className="metrics-container">
          {Object.entries(animatedScores.details).map(([key, value]) => (
            <div key={key} className="metric-item">
              <div className="metric-header">
                <span>{key}</span>
                <span>{Math.round(value)}%</span>
              </div>
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{
                    width: `${value}%`,
                    backgroundColor: getScoreColor(value),
                    minWidth: "2px",
                  }}
                />
              </div>
            </div>
          ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TitleOptimizer;